export const authConstants = {
  LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  SIGNUP_REQUEST: "USER_SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "USER_SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "USER_SIGNUP_FAILURE",
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',
  LOGOUT: 'USERS_LOGOUT'
}

export const alertConstants = {
    SUCCESS: 'SUCCESS',
    ERROR_REQUEST:"ERROR_REQUEST",
    ERROR_SUCCESS: 'ERROR_SUCCESS',
    ERROR_DONE:"ERROR_DONE",
    CLEAR: 'CLEAR',
    ERROR: 'ERROR'
};


export const insideConstants = {
  VIEW_FILE_REQUEST: 'VIEW_FILE_REQUEST',
  DELETE_FILE: 'DELETE_FILE',
  VIEW_FILE_SUCCESS: 'VIEW_FILE_SUCCESS',
  VIEW_FILE_REQUEST_DONE: 'VIEW_FILE_REQUEST_DONE',

  ADD_SAMPLE_REQUEST: 'ADD_SAMPLE_REQUEST',
  ADD_SAMPLE_REQUEST_SUCCESS: 'ADD_SAMPLE_REQUEST_SUCCESS',
  ADD_SAMPLE_REQUEST_DONE: 'ADD_SAMPLE_REQUEST_DONE',

  SAMPLES_REQUEST: 'SAMPLES_REQUEST',
  SAMPLES_REQUEST_SUCCESS: 'SAMPLES_REQUEST_SUCCESS',
  SAMPLES_REQUEST_DONE: 'SAMPLES_REQUEST_DONE',
  SAMPLES_REQUEST_DELETE: 'SAMPLES_REQUEST_DELETE',
  SAMPLES_REQUEST_DELETE_FAILURE: 'SAMPLES_REQUEST_DELETE_FAILURE',

  ANALYSIS_REQUEST:'ANALYSIS_REQUEST',
  GET_HUMAN_TYPE:'GET_HUMAN_TYPE',
  ANALYSIS_REQUEST_SUCCESS: 'ANALYSIS_REQUEST_SUCCESS',
  ANALYSIS_REQUEST_DONE: 'ANALYSIS_REQUEST_DONE',

  ADD_PROJECT_REQUEST: 'ADD_PROJECT_REQUEST',
  ADD_PROJECT_SUCCESS: 'ADD_PROJECT_SUCCESS',
  ADD_PROJECT_DONE: 'ADD_PROJECT_DONE',
  PROJECT_REQUEST: 'PROJECT_REQUEST',
  PROJECT_REQUEST_SUCCESS: 'PROJECT_REQUEST_SUCCESS',
  PROJECT_REQUEST_DELETE_204: 'PROJECT_REQUEST_DELETE_204',
  PROJECT_REQUEST_DONE: 'PROJECT_REQUEST_DONE',
  PROJECT_REQUEST_DELETE: 'PROJECT_REQUEST_DELETE',
  PROJECT_ANALYSIS_REQUEST:'PROJECT_ANALYSIS_REQUEST',
  PROJECT_ANALYSIS_REQUEST_SUCCESS:'PROJECT_ANALYSIS_REQUEST_SUCCESS',
  PROJECT_ANALYSIS_REQUEST_DONE:'PROJECT_ANALYSIS_REQUEST_DONE',
  PREQC_TABLE_ANALYSIS_REQUEST:'PREQC_ANALYSIS_REQUEST',
  PREQC_TABLE_ANALYSIS_REQUEST_SUCCESS:'PREQC_TABLE_ANALYSIS_REQUEST_SUCCESS',
  PREQC_TABLE_ANALYSIS_REQUEST_DONE:'PREQC_TABLE_ANALYSIS_REQUEST_DONE',
  ANALYSIS_TRIMMING_REQUEST: 'ANALYSIS_TRIMMING_REQUEST',
  ANALYSIS_TRIMMING_REQUEST_SUCCESS: 'ANALYSIS_TRIMMING_REQUEST_SUCCESS',
  ANALYSIS_TRIMMING_REQUEST_DONE: 'ANALYSIS_TRIMMING_REQUEST_DONE',

  DUPLICATE_REMOVAL_REQUEST:"DUPLICATE_REMOVAL_REQUEST",
  DUPLICATE_REMOVAL_REQUEST_SUCCESS:"DUPLICATE_REMOVAL_REQUEST_SUCCESS",
  DUPLICATE_REMOVAL_REQUEST_DONE:"DUPLICATE_REMOVAL_REQUEST_DONE",

  VARIANT_COLLECT_REQUEST:"VARIANT_COLLECT_REQUEST",
  VARIANT_COLLECT_REQUEST_SUCCESS:"VARIANT_COLLECT_REQUEST_SUCCESS",
  VARIANT_COLLECT_REQUEST_DONE:"VARIANT_COLLECT_REQUEST_DONE",
  VARIANT_RAW_REQUEST:"VARIANT_RAW_REQUEST",
  VARIANT_RAW_REQUEST_SUCCESS:"VARIANT_RAW_REQUEST_SUCCESS",
  VARIANT_RAW_REQUEST_DONE:"VARIANT_RAW_REQUEST_DONE",
  PROJECT_SAMPLES_REQUEST:'PROJECT_SAMPLES_REQUEST',
  PROJECT_SAMPLES_REQUEST_SUCCESS:'PROJECT_SAMPLES_REQUEST_SUCCESS',
  PROJECT_SAMPLES_REQUEST_DONE:'PROJECT_SAMPLES_REQUEST_DONE',

  SAMTOOL_FLAGSTAT_REQUEST:'SAMTOOL_FLAGSTAT_REQUEST',
  SAMTOOL_FLAGSTAT_REQUEST_SUCCESS:'SAMTOOL_FLAGSTAT_REQUEST_SUCCESS',
  SAMTOOL_FLAGSTAT_REQUEST_DONE:'SAMTOOL_FLAGSTAT_REQUEST_DONE',

  STAT_VARIANT_FILE_REQUEST:'STAT_VARIANT_FILE_REQUEST',
  STAT_VARIANT_FILE_REQUEST_SUCCESS:'STAT_VARIANT_FILE_REQUEST_SUCCESS',
  STAT_VARIANT_FILE_REQUEST_DONE:'STAT_VARIANT_FILE_REQUEST_DONE',
  CIRCOS_REQUEST:"CIRCOS_REQUEST",
  CIRCOS_REQUEST_SUCCESS:"CIRCOS_REQUEST_SUCCESS",
  CIRCOS_REQUEST_DONE:"CIRCOS_REQUEST_DONE",
  WORLDMAP_REQUEST:"WORLDMAP_REQUEST",
  WORLDMAP_REQUEST_SUCCESS:"WORLDMAP_REQUEST_SUCCESS",
  WORLDMAP_REQUEST_DONE:"WORLDMAP_REQUEST_DONE",
  ADD_ANALYSIS_REQUEST:"ADD_ANALYSIS_REQUEST",
  ADD_ANALYSIS_REQUEST_SUCCESS:"ADD_ANALYSIS_REQUEST_SUCCESS",
  ADD_ANALYSIS_REQUEST_DONE:"ADD_ANALYSIS_REQUEST_DONE",
  GENE_REQUEST:"GENE_REQUEST",
  GENE_REQUEST_SUCCESS:"GENE_REQUEST_SUCCESS",
  GENE_REQUEST_DONE:"GENE_REQUEST_DONE",
  GENE_DYNAMIC_WOLRD_REQUEST:'GENE_DYNAMIC_WOLRD_REQUEST',
  GENE_DYNAMIC_WOLRD_SUCCESS:'GENE_DYNAMIC_WOLRD__SUCCESS',
  GENE_DYNAMIC_WOLRD_DONE:'GENE_DYNAMIC_WOLRD_DONE',
  SNPEFF_REQUEST:"SNPEFF_REQUEST",
  SNPEFF_REQUEST_SUCCESS:"SNPEFF_REQUEST_SUCCESS",
  SNPEFF_DONE:"SNPEFF_DONE",
  SUNBURST_REQUEST:"SUNBURST_REQUEST",
  SUNBURST_REQUEST_SUCCESS:"SUNBURST_REQUEST_SUCCESS",
  SUNBURST_DONE:"SUNBURST_DONE",
  DATATABLE_REQUEST:"DATATABLE_REQUEST",
  DATATABLE_REQUEST_SUCCESS:"DATATABLE_REQUEST_SUCCESS",
  DATATABLE_DONE:"DATATABLE_DONE",
  DOCUMENT_REQUEST:"DOCUMENT_REQUEST",
  DOCUMENT_REQUEST_SUCCESS:"DOCUMENT_REQUEST_SUCCESS",
  DOCUMENT_DONE:"DOCUMENT_DONE",


  COLLECT_HS_METRICS_REQUEST: "ALL_COLLECT_HS_METRICS_REQUEST",
  COLLECT_HS_METRICS_REQUEST_SUCCESS: "ALL_COLLECT_HS_METRICS_REQUEST_SUCCESS",
  COLLECT_HS_METRICS_REQUEST_DONE: "ALL_COLLECT_HS_METRICS_REQUEST_DONE",

  SOMATIC_STATS_REQUEST: "ALL_SOMATIC_STATS_REQUEST",
  SOMATIC_STATS_REQUEST_SUCCESS: "SOMATIC_STATS_REQUEST_SUCCESS",
  SOMATIC_STATS_REQUEST_DONE: "SOMATIC_STATS_REQUEST_DONE",


  COLLECT_MULTIPLE_METRICS_REQUEST: "ALL_COLLECT_MULTIPLE_METRICS_REQUEST",
  COLLECT_MULTIPLE_METRICS_REQUEST_SUCCESS: "ALL_COLLECT_MULTIPLE_METRICS_REQUEST_SUCCESS",
  COLLECT_MULTIPLE_METRICS_REQUEST_DONE: "ALL_COLLECT_MULTIPLE_METRICS_REQUEST_DONE",


  COLLECT_QUALITY_YIELD_METRICS_REQUEST: "ALL_COLLECT_QUALITY_YIELD_METRICS_REQUEST",
  COLLECT_QUALITY_YIELD_METRICS_REQUEST_SUCCESS: "ALL_COLLECT_QUALITY_YIELD_METRICS_REQUEST_SUCCESS",
  COLLECT_QUALITY_YIELD_METRICS_REQUEST_DONE: "ALL_COLLECT_QUALITY_YIELD_METRICS_REQUEST_DONE",

  VCF_FILE_GENERATOR_REQUEST: "VCF_FILE_GENERATOR_REQUEST",
  VCF_FILE_GENERATOR_REQUEST_SUCCESS: "VCF_FILE_GENERATOR_REQUEST_SUCCESS",
  VCF_FILE_GENERATOR_REQUEST_DONE: "VCF_FILE_GENERATOR_REQUEST_DONE",


  DOCUMENT_SEARCH_REQUEST:"DOCUMENT_SEARCH_REQUEST",
  DOCUMENT_SEARCH_REQUEST_SUCCESS:"DOCUMENT_SEARCH_REQUEST_SUCCESS",
  DOCUMENT_SEARCH_DONE:"DOCUMENT_SEARCH_DONE",

  ANALYSIS_DETAILS_REQUEST:"ANALYSIS_DETAILS_REQUEST",
  ANALYSIS_DETAILS_REQUEST_SUCCESS:"ANALYSIS_DETAILS_REQUEST_SUCCESS",
  ANALYSIS_DETAILS_REQUEST_DONE:"ANALYSIS_DETAILS_REQUEST_DONE",

  DASH_ADVANCE_ANALYSIS_REQUEST:"DASH_ADVANCE_ANALYSIS_REQUEST",
  DASH_ADVANCE_ANALYSIS_REQUEST_SUCCESS:"DASH_ADVANCE_ANALYSIS_REQUEST_SUCCESS",
  DASH_ADVANCE_ANALYSIS_REQUEST_DONE:"DASH_ADVANCE_ANALYSIS_REQUEST_DONE",

  DOC_REQUEST: "DOC_REQUEST",
  DOC_REQUEST_SUCCESS: "DOC_REQUEST_SUCCESS",
  DOC_REQUEST_DONE: "DOC_REQUEST_DONE",

  SET_ADVANCE_ANALYSIS_RENDER_TAG: "SET_ADVANCE_ANALYSIS_RENDER_TAG",

  NOTIFICATION_REQUEST: "NOTIFICATION_REQUEST",
  NOTIFICATION_REQUEST_SUCCESS: "NOTIFICATION_REQUEST_SUCCESS",
  NOTIFICATION_REQUEST_DONE: "NOTIFICATION_REQUEST_DONE",
  SPOT_INSTANCE_REQUEST_STATE: "SPOT_INSTANCE_REQUEST_STATE",
  USER_FTP_PASSWORD: "USER_FTP_PASSWORD",
  ELASTIC_INDEX_DETAILS: "ELASTIC_INDEX_DETAILS",
  CLEAR_STATE:"CLEAR_STATE"
};
